
import { Component, Mixins } from 'vue-property-decorator';
import ResearchPageHeader from '@/components/ResearchPageHeader.vue';
import ResearchsSearchForm, { SearchParamsObject } from '@/components/ResearchsSearchForm.vue';
import SecondaryTvNetworkTable from '@/components/SecondaryTvNetworkTable.vue';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import {
  DailyAudienceSearchParamsDTO,
  DailyAudienceExportParamsDTO,
} from '@/data/dto/daily-audience.dto';
import { SeriesItem } from '@/data/dto/series-item.dto';
import DatetimeUtil from '@/utils/datetime.util';
import Highcharts from 'highcharts';
import HighchartsUtil, { AudienceTooltipDataSeries, IExtendedChartPoint, IChartExtremes } from '@/utils/highcharts.util';
import { MainProgram, SimultaneousProgramsSearchParamsDTO } from '@/data/dto/simultaneous-programs.dto';
import HighchartsMixins from '@/mixins/highcharts.mixin';
import ExportButton from '@/components/ExportToExcelButton.vue';
import moment from 'moment';
import { ViewersProjectionBaseType, formatNumberToStringWithSymbol, numberToBrString } from "@/utils/number.util";
import { TargetBaseType, getTargetBaseTypeByTargetName } from '@/utils/viewers-projection.util';

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    SecondaryTvNetworkTable,
    DataFormatControlsContainer,
    ExportButton,
  }
})
export default class DailyAudience extends Mixins(HighchartsMixins) {
  startZoomItemsCount = 15;
  selectedTargetBaseType: TargetBaseType = 'residences';
  yKey: ChartDataType = 'audience';
  viewersProjectionBase: ViewersProjectionBaseType = {
    value: 1000000,
    label: 'Milhão',
    baseSymbol: 'M',
    decimalPlaces: 1,
  };

  get showViewersProjectionBaseController(): boolean {
    const series = this.chartOptions.series as SeriesItem[];
    const currentSeriesHasViewersProjection = series.some(({ showViewersProjection }) => showViewersProjection);
    return currentSeriesHasViewersProjection && this.yKey === 'audience';
  }

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: DailyAudience, customTickPositions?: number[]): Highcharts.Options {
    return {
      chart: {
        spacingLeft: 30,
        spacingRight: 40,
        events: {
          load: function() {
            const { min, max } = context.getChartExtremes(context.startZoomItemsCount, this.xAxis);
            this.xAxis[0].setExtremes(min, max);
          },
        }
      },
      rangeSelector: {
        enabled: false
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: true,
      },
      navigator: {
        adaptToUpdatedData: false,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
          marker: {
            enabled: true,
            radius: 4,
            symbol: 'circle',
          }
        },
      },
      xAxis: {
        type: 'datetime',
        tickPositions: customTickPositions,
        labels: {
          events: {
            click: function() { context.onChartDayClicked(this) },
          },
          format: '{value:%d/%m}<br>{value:%a}',
          overflow: 'allow',
        },
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        }
      },
      tooltip: {
        shared: true,
        useHTML: true,
        split: false,
        backgroundColor: 'rgba(255,255,255,.9)',
        formatter: function() {
          if (!this.points) return;

          const date = moment(this.x).utc().format('DD/MM/YYYY HH:mm');
          const tooltipSeries: AudienceTooltipDataSeries[] = this.points.map(point => {
            const pointData = point.point as IExtendedChartPoint;
            const seriesData: Highcharts.Series = point.series;

            const tooltipObject: AudienceTooltipDataSeries = {
              color: pointData.color,
              label: seriesData.name,
              audienceOrSharePercentage: pointData.y,
            };

            const showViewersProjectionBase = !!(typeof pointData.viewersProjection === 'number' && context.yKey === 'audience');
            if (showViewersProjectionBase) {
              tooltipObject.viewersProjection = context.formattedViewersProjection(pointData.viewersProjection, context.viewersProjectionBase);
            }

            return tooltipObject;
          });

          return HighchartsUtil.renderAudienceHTMLTooltip({
            title: date,
            series: tooltipSeries,
          }, context.selectedTargetBaseType);
        },
      },
      series: [],
    };
  }

  secondaryTvNetworks = [];
  mainProgram: MainProgram | null = null;

  currentSearchParams: {
    valueOnly: DailyAudienceSearchParamsDTO,
    completeData: Record<string, any>
  } | null = null;

  currentProgram = '';
  currentComparationDate = '';

  get currentDate(): string {
    return  `${DatetimeUtil.stringDateDTOToView(this.currentComparationDate)} (${DatetimeUtil.weekday(this.currentComparationDate)})`;
  }

  get formattedMainProgramExhibitionPeriod(): string {
    if (!this.mainProgram) return '';
    return `
      ${DatetimeUtil.stringTimeToView(this.mainProgram.startTime)}
      às
      ${DatetimeUtil.stringTimeToView(this.mainProgram.endTime)}
    `;
  }

  get exportToExcelSearchParams(): DailyAudienceExportParamsDTO | null {
    if(!this.currentSearchParams) return null;

    const params = this.currentSearchParams;
    return {
      dailyAudience: {
        params: params.valueOnly,
        header: `Audiência diária - ${params.completeData.program.label}`,
      },
      simultaneousPrograms: {
        params: this.generateSimultaneousProgramsParams(this.currentComparationDate) as SimultaneousProgramsSearchParamsDTO,
        header: `Programas simultâneos - ${params.completeData.program.label} - ${params.valueOnly.endDate}`,
      }
    };
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: SeriesItem[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onChartDayClicked(label: any): void {
    const date = label.pos;
    const formattedDate = DatetimeUtil.UTCTimestampToDTO(date);
    this.getSimultaneousPrograms(formattedDate);
  }

  onViewersProjectionBaseChange(base: ViewersProjectionBaseType): void {
    this.viewersProjectionBase = base;
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  formattedViewersProjection(numberOfViewers = 0, base: ViewersProjectionBaseType = this.viewersProjectionBase ): string {
    return formatNumberToStringWithSymbol(numberOfViewers, base);
  }

  getChartExtremes(zoomLimit = 15, axis: Highcharts.Axis[] = []): IChartExtremes {
    const baseSeriesData = axis[0]?.series[0]?.data;
    if (!baseSeriesData) return { min: 0, max: 0 };

    const maxIndex = zoomLimit <= baseSeriesData.length ? zoomLimit - 1 : baseSeriesData.length - 1;
    const min: number = baseSeriesData[0].x;
    const max: number = baseSeriesData[maxIndex].x;
    return { min, max };
  }

  generateSimultaneousProgramsParams(date: string, params = this.currentSearchParams): SimultaneousProgramsSearchParamsDTO | undefined {
    if (!params || !date) return undefined;
    return {
      exhibitionDate: date,
      mainProgram: params.valueOnly.program,
      mainProgramPresentation: params.completeData.program.presentation,
      mainProgramBoard: params.completeData.program.programBoard,
      mainTvNetworkId: params.valueOnly.mainTvNetwork,
      marketId: params.valueOnly.market,
      secondaryTvNetworkIds: params.valueOnly.secondaryTvNetworks,
      tvNetworkType: params.valueOnly.tvNetworkType,
    };
  }

  async getDailyAudience(params: SearchParamsObject): Promise<void> {
    try {
      const searchParams = {
        ...params.valueOnly,
        program: params.completeData.program.program,
        presentation: params.completeData.program.presentation,
        programBoard: params.completeData.program.programBoard,
      } as DailyAudienceSearchParamsDTO;

      if (params.completeData.target) {
        this.selectedTargetBaseType = getTargetBaseTypeByTargetName(params.completeData.target.label);
      }

      //reset values before new search
      if ( this.chartOptions.series ) this.chartOptions.series = [];
      this.secondaryTvNetworks = [];
      this.currentProgram = '';
      this.currentSearchParams = null;
      this.mainProgram = null;
      this.currentComparationDate = '';

      const getDailyAudienceResponse = await this.$store.dispatch('getDailyAudience', searchParams);

      if (!getDailyAudienceResponse) {
        this.$store.commit('showAlert', {
          message: 'Não há dados disponíveis para a consulta realizada.',
          type: 'warning',
        });
        return;
      }

      const { series } = getDailyAudienceResponse;

      if (series && series[0] && series[0].data && series[0].data.length) {
        // set tick positions: set to undefined when there's only one point in the series, to use default tick positions
        // it's necessary to avoid a highcharts's bug with single item arrays
        const tickPositions = series[0].data.map((item:any) => item.x);
        const fixedTickPositions = tickPositions.length === 1 ? undefined : tickPositions;

        //generate chart
        this.chartOptions = this.generateChartOptions(this, fixedTickPositions);
        this.mountChartSeriesYAxis(series);

        //set current data
        this.currentSearchParams = {
          valueOnly: searchParams,
          completeData: params.completeData,
        };
        this.currentProgram = params.completeData.program.label;

        //get simultaneous programs by last date on the series
        const lastItemOnSeriesIndex = series[0].data.length - 1;
        const lastDateOnSeries = series[0].data[lastItemOnSeriesIndex].x;
        const simultaneousProgramsDate = DatetimeUtil.UTCTimestampToDTO(lastDateOnSeries);
        this.getSimultaneousPrograms(simultaneousProgramsDate);

      } else {
        this.$store.commit('showAlert', { message: 'Nenhum dado encontrado com os filtros selecionados.', type: 'warning'});
      }
    } catch (e) {
      this.chartOptions.series = [];
      this.currentSearchParams = null;
      this.currentProgram = '';
    }
  }

  async getSimultaneousPrograms(date: string): Promise<void> {
    try {
      const params = this.generateSimultaneousProgramsParams(date);
      if (params) {
        const simultaneousPrograms = await this.$store.dispatch('getSimultaneousPrograms', params);
        if (!simultaneousPrograms) {
          this.$store.commit('showAlert', {
            message: 'Nenhum programa simultâneo foi encontrado para as emissoras selecionadas.',
            type: 'warning',
          });
        } else {
          this.secondaryTvNetworks = simultaneousPrograms.secondaryTvNetworks;
          this.mainProgram = simultaneousPrograms.mainProgram;
          this.currentComparationDate = date;
        }
      }
    } catch (e) {
      this.secondaryTvNetworks = [];
      this.mainProgram = null;
      this.currentComparationDate = '';
    }
  }
}
