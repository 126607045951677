
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SecondaryTvNetwork } from '@/data/dto/simultaneous-programs.dto';

@Component
export default class SecondaryTvNetworkTable extends Vue {
  @Prop() private network!: SecondaryTvNetwork;

  getTimeLabel(time:string): string {
    return time ? time.slice(0,5) : '';
  }
}
